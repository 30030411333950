<template>
    <div class="content">
        <p class="month">本月账户供款情况</p>
        <div class="big-box">
            <div v-for="(item, index) in contracts" :key="index">
                <div
                    :id="`echart${index}`"
                    style="
                        width: 100%;
                        height: 5rem;
                        border-radius: 0.10667rem;
                    "
                ></div>
            </div>
            <!-- <div id="echarts"></div> -->
        </div>
        <div class="account">
            <div class="first-month">
                <p>本月供款总额</p>
                <div>
                    <h1>{{ baseInfo.monthPaymentSum }}</h1>
                    <span>{{ baseInfo.currency }}</span>
                </div>
            </div>
            <div class="first-month">
                <p>累计供款总额</p>
                <div>
                    <h1>{{ baseInfo.accPaymentSum }}</h1>
                    <span>{{ baseInfo.currency }}</span>
                </div>
            </div>
        </div>
        <div class="concept">
           {{encourageMsg}}
        </div>
    </div>
</template>

<script>
export default {
    props: {
        userUuid: String,
    },
    data() {
        return {
            baseInfo: {},
            contracts: [],
            encourageMsg:'',
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.allData();
        });
    },

    methods: {
        allData() {
            this.$util
                .get("itaMonthReport/getAccountPaymentInfo", {
                    userUuid: this.userUuid,
                })
                .then((res) => {
                    this.baseInfo = res.baseInfo;
                    this.encourageMsg = res.encourageMsg
                    this.contracts = res.contracts;
                    this.$nextTick(() => {
                        this.drawLine();
                    });
                });
        },
        drawLine() {
            const arr = [];
            var stext = "";
            this.contracts.forEach((item, index) => {
                item.dateList.forEach((el) => {
                    stext = el.substring(el.lastIndexOf("-") + 1);
                    if (stext.charAt(0) == 0) {
                        stext = stext.replace("0", "");
                    }
                    arr.push(stext + "月");
                });
                // 基于准备好的dom，初始化echarts实例
                let Chart = this.$echarts.init(
                    document.getElementById(`echart${index}`)
                );
                // 绘制图表
                Chart.setOption({
                    title: [
                        {
                            text: item.contractName + "(含附加)",
                            left: "0.42667rem",
                            textStyle: {
                                fontFamily: "PingFang SC",
                                fontSize: ".32rem",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                color: "#333",
                            },
                        },
                        {
                            text: `本月${item.conMonthPaymentSum}/累计${item.conAccPaymentSum}USD`,
                            right: "0.42667rem",
                            textStyle: {
                                fontFamily: "PingFang SC",
                                fontSize: ".32rem",
                                fontStyle: "normal",
                                fontWeight: "bold",
                                color: "#828282",
                            },
                        },
                    ], //!!!
                    grid: {
                        x: 16,
                        x2: 16,
                        borderWidth: 1,
                    },
                    xAxis: {
                        type: "category",
                        data: [...new Set(arr)],
                        axisTick: {
                            show: false,
                        },
                        axisLine: {
                            // show:false
                            lineStyle: {
                                color: "#F2F2F2",
                            },
                        },
                        axisLabel: {
                            textStyle: {
                                // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                                color: "#BDBDBD",
                                fontSize: 12,
                                marginLeft: 10,
                            },
                            fontWeight: "bold",
                        },
                    },
                    yAxis: {
                        type: "value",
                        show: false,
                    },
                    series: [
                        {
                            data: item.dataList,
                            type: "bar",
                            itemStyle: {
                                normal: {
                                    //这里是重点
                                    color: function (params) {
                                        //注意，如果颜色太少的话，后面颜色不会自动循环，最好多定义几个颜色
                                        var colorList = [
                                            "#E0E0E0",
                                            "#E0E0E0",
                                            "#E0E0E0",
                                            "#E0E0E0",
                                            "#E0E0E0",
                                            "#0837F6",
                                        ];

                                        return colorList[params.dataIndex];
                                    },
                                },
                            },
                        },
                    ],
                });
            });
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    padding: 48px 32px 0;
}
.month {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 32px;
}
.big-box {
    border: 1px solid #f2f2f2;
    padding: 32px;
    position: relative;
}
// #echart0 {
//     width: 100%;
//     height: 280px;
//     border-radius: 8px;
// }
// #echarts {
//     width: 100%;
//     height: 280px;
//     // border: 1px solid #f2f2f2;
//     border-radius: 8px;
// }
.account {
    display: flex;
    padding: 48px 0;
    .first-month {
        flex: 1;
        p {
            font-size: 24px;
            color: #828282;
            font-weight: bold;
        }
        div {
            display: flex;
            align-items: flex-end;
            h1 {
                font-size: 46px;
                color: #000;
                margin: 18px 16px 0 0;
            }
            span {
                margin-bottom: 10px;
                font-size: 24px;
                font-weight: bold;
                color: #bdbdbd;
            }
        }
    }
}
.concept {
    padding: 0 0 48px;
    font-size: 28px;
    color: #bdbdbd;
    font-weight: 400;
    line-height: 48px;
}
</style>
<template>
    <div class="content">
        <p class="month">本月收益率走势</p>
        <div id="chart"></div>
        <div class="account">
            <div class="first-month">
                <p>月初累计收益率</p>
                <div>
                    <h1 :style="{ color: baseInfo.beginReturnRateColor }">
                        {{ baseInfo.beginReturnRate }}
                    </h1>
                </div>
            </div>
            <div class="first-month">
                <p>月末累计收益率</p>
                <div>
                    <h1 :style="{ color: baseInfo.endReturnRateColor }">
                        {{ baseInfo.endReturnRate }}
                    </h1>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "hello",
    props: {
        userUuid: String,
    },
    data() {
        return {
            baseInfo: {},
            maxValue: "",
            minValue: "",
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.drawLine();
        });
    },
    methods: {
        drawLine() {
            this.$util
                .get("itaMonthReport/getAccountRateInfo", {
                    userUuid: this.userUuid,
                })
                .then((res) => {
                    // console.log(res);
                    const list = [];
                    res.trendInfo.dateList.forEach((el) => {
                        list.push(el.replace(/-/g, "/"));
                    });
                    res.trendInfo.dateList = [...new Set(list)];
                    this.baseInfo = res.baseInfo;
                    const sum = res.trendInfo.dateList.length - 2;
                    this.maxValue = res.trendInfo.dataList[0];
                    res.trendInfo.dataList.forEach(
                        (item) =>
                            (this.maxValue =
                                item > this.maxValue ? item : this.maxValue)
                    );
                    this.minValue = res.trendInfo.dataList[0];
                    res.trendInfo.dataList.forEach(
                        (item) =>
                            (this.minValue =
                                item < this.minValue ? item : this.minValue)
                    );

                    // 基于准备好的dom，初始化echarts实例
                    let Chart = this.$echarts.init(
                        document.getElementById("chart")
                    );
                    // 绘制图表
                    Chart.setOption({
                        xAxis: {
                            type: "category",
                            data: res.trendInfo.dateList,
                            splitLine: { show: false },
                            axisTick: {
                                show: false,
                            },
                            axisLine: {
                                // show:false
                                lineStyle: {
                                    color: "#F2F2F2",
                                },
                            },
                            axisLabel: {
                                interval: sum,
                                textStyle: {
                                    // 其余属性默认使用全局文本样式，详见TEXTSTYLE
                                    color: "#BDBDBD",
                                    fontSize: 12,
                                    marginLeft: 10,
                                },
                                fontWeight: "bold",
                            },
                        },
                        yAxis: {
                            show: false,
                            max: this.maxValue,
                            min: this.minValue,
                        },
                        series: [
                            {
                                data: res.trendInfo.dataList,
                                type: "line",
                                smooth: true,
                                symbol: "none",
                                color: "#0837F6",
                            },
                        ],
                    });
                });
        },
    },
};
</script>

<style lang="less" scoped>
.content {
    padding: 50px 32px 0;
}
.month {
    font-size: 24px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 32px;
}
#chart {
    width: 100%;
    height: 352px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
}
.account {
    display: flex;
    padding: 48px 0;
    border-bottom: 1px solid #f5f5f5;
    .first-month {
        flex: 1;
        p {
            font-size: 24px;
            color: #828282;
            font-weight: bold;
        }
        div {
            display: flex;
            align-items: flex-end;
            h1 {
                font-size: 46px;
                color: #000;
                margin: 18px 16px 0 0;
            }
        }
    }
}
</style>